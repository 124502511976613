import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getTopTrades } from "../../api/api";
import { TradePresentationItem } from "../../types/types";
import { getUserName } from './supportingComponents';

export const TopTrades: React.FC<{ assetId: string }> = ({ assetId }) => {
  const [data, setData] = useState<TradePresentationItem[]>([]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const fetchedData = await getTopTrades(assetId);
        const dataWithKeys = fetchedData.map((item, index) => ({
          ...item,
          uniqueKey: `${item.user_id}-${item.id}-${index}`,
        }));
        setData(dataWithKeys);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [assetId]);

  if (data.length === 0) {
    return <div className="p-4 h-74"></div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="h-74 rounded-lg border border-gray-200 mb-2"
    >
      <div className="text-xs text-center py-1 text-gray-500">
        24H Top Trades
      </div>
      
      {/* Table Header */}
      <div className="w-full mx-auto">
        <div className="flex px-2 pb-1 text-sm font-semibold text-gray-700">
          <div className="w-1/8 text-left pl-2">Rank</div>
          <div className="w-1/4 text-right pl-2 pr-2">User</div>
          <div className="w-1/3 text-right pr-4">$ Gain</div>
          <div className="w-1/3 text-right pr-4">% Gain</div>
        </div>
      </div>
      
      {/* Scrollable Trade Rows */}
      <div className="overflow-hidden h-64 w-full mx-auto">
        <motion.div
          animate={{
            y: ["0%", "-100%"],
          }}
          transition={{
            duration: 240,
            ease: "linear",
            repeat: Infinity,
          }}
        >
          {data.map((item, index) => (
            <TableRow key={item.uniqueKey} item={item} index={index + 1} />
          ))}
          {/* Duplicate the list to create an infinite loop effect */}
          {data.map((item, index) => (
            <TableRow key={`${item.uniqueKey}-duplicate`} item={item} index={index + 1} />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

// Component for individual table rows
const TableRow: React.FC<{ item: TradePresentationItem; index: number }> = ({ item, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/portfolio/${item.user_id}`);
  };

  return (
    <div
      className="flex px-4 py-2 text-sm text-[var(--turquoise-green)] hover:bg-gray-100 cursor-pointer"
      onClick={handleClick}
    >
      <div className="w-1/8 text-left pl-1 text-gray-500 text-xs">
        #{index}
      </div>
      <div className="w-1/4 text-right pl-2 text-gray-500">
        {getUserName(item.user_id)}
      </div>
      <div className="w-1/3 text-right pr-2 pl-2">
        ${item.absolute_pnl}
      </div>
      <div className="w-1/3 text-right pr-2">
        {item.percentage_pnl}%
      </div>
    </div>
  );
};