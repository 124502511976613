import moment from 'moment';
import { axiosInstance } from './axios';
import { TableData, Column, MarketData, MultiScatterChartData, LineChartData, Asset, Pm25Hourly, Pm25Daily, TradePresentationItem } from '../types/types';
import {
  createMultiScatterChartData,
  generatePriceLineChartData,
  generateDividendLineChartData,
  generateAssetsData,
  generatePm25HourlyData,
  generatePm25DailyData,
  generateAssetInfoData,
  generateTradePresentationData,
} from '../helpers/dataGeneration';
import { isDevelopment } from './constants';

export const getTopTrades = async (assetId: string): Promise<TradePresentationItem[]> => {
  const url = `top_trade_gainers?asset_id=${assetId}`;
  if (isDevelopment) {
    return generateTradePresentationData().trade_presentation_queue;
  }
  return axiosInstance.get(url).then((response) => {
    return response.data['top_trade_gainers'];
  });
}

export const getTransactionQueueData = async (assetId?: string): Promise<TradePresentationItem[]> => {
  const url = assetId ? `trade_presenter?asset_id=${assetId}` : `trade_presenter`;
  if (isDevelopment) {
    return generateTradePresentationData().trade_presentation_queue;
  }
  return axiosInstance.get(url).then((response) => {
    return response.data['trade_presentation_queue'];
  });
}

const getScatterChartData = async (): Promise<MultiScatterChartData | null> => {
  const url = `correlations`;
  try {
    return axiosInstance.get(url).then((response) => {
      if (isDevelopment) {
        return createMultiScatterChartData();
      } else {
        const airMeasure = response.data['air_measure_data'];
        const priceDividend = response.data['dividend_to_price_data'];
        const priceAirMeasure = response.data['price_to_air_measure_data'];
        return { airMeasure, priceDividend, priceAirMeasure }
      }
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const getMarketOverview = async (): Promise<MarketData | null> => {
  const url = `market_overview`;
  try {
    return axiosInstance.get(url).then((response) => {
      return response.data['data'];
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const getPriceChangePrediction = async (asset_id: string): Promise<number | null> => {
  const url = `price_change_prediction/${asset_id}`;
  try {
    return axiosInstance.get(url).then((response) => {
      return response.data['difference_prediction'];
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const getAssets = async (getPM25: boolean = true): Promise<Asset[] | null> => {
  const url = `assets?get_pm25=${getPM25}`;
  try {
    if (isDevelopment) {
      return generateAssetsData();
    }
    const response = await axiosInstance.get(url);
    if (response.status !== 200) {
      console.error(`Failed with status code: ${response.status}`);
      console.error(response);
      throw new Error(response.statusText);
    }
    return response.data['assets'];
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const getPm25ByHourAndDay = async (assetId: number): Promise<([Pm25Daily[], Pm25Hourly[], string]) | null> => {
  const url = `air_quality?asset_id=${assetId}`;
  try {
    if (isDevelopment) {
      return [generatePm25DailyData(), generatePm25HourlyData(), 'San Fransico'];
    }
    const response = await axiosInstance.get(url);
    if (response.status !== 200) {
      console.error(`Failed with status code: ${response.status}`);
      console.error(response);
      throw new Error(response.statusText);
    }
    const byDay = response.data['air_quality_by_day'] as Pm25Daily[];
    const byHour = response.data['air_quality_by_hour'] as Pm25Hourly[];
    const assetName = response.data['asset_name'] as string;
    return [byDay, byHour, assetName]
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}


const getAssetTableData = async (forOnboarding: boolean = false): Promise<TableData | null> => {
  const url = `assets_table?forOnboarding=${forOnboarding}`;
  try {
    if (isDevelopment) {
      return generateAssetInfoData();
    }
    const response = await axiosInstance.get(url);
    if (response.status !== 200) {
      console.error(`Failed with status code: ${response.status}`);
      throw new Error(response.statusText);
    }
    return {
      data: response.data['data'],
      columns: response.data['columns'] as unknown as Column[]
    };
  } catch (error: any) {
    console.log(error.message)
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
};

const swap = async (
  user_id: string,
  pool_id: string,
  token_in: string,
  amount_in: number
) => {
  const amount_in_as_number = Number(amount_in);
  try {
    const response = await axiosInstance.post(`swap`, {
      user_id,
      pool_id,
      token_in,
      amount_in: amount_in_as_number
    });

    return response.data;
  } catch (error: any) {
    console.error(`Error fetching order info: ${error.message}`);
    throw error;
  }

};

const userTokenQuantity = async (
  user_id: string,
  token_id: string,
) => {
  try {
    const response = await axiosInstance.get(`user-token-quantity`, {
      params: {
        user_id,
        token_id
      }
    });
    if (response.status !== 200) {
      console.error(`Failed with status code: ${response.status}`);
      console.error(response);
      throw new Error(response.statusText);
    }

    const { quantity } = response.data;
    return { quantity }
  } catch (error: any) {
    console.error(`Error fetching order info: ${error.message}`);
    throw error;
  }
}

const getPrice = async (token_id: string) => {
  const response = await axiosInstance.get(`price`, {
    params: {
      token_id
    }
  });
  const { price } = response.data;
  return price;
};


const getPriceChartData = async (assetId: number = 1, period: string = 'DAY'): Promise<LineChartData | undefined> => {
  const url = `price_history?asset_id=${assetId}&period=${period}`;
  try {
    if (isDevelopment) {
      return generatePriceLineChartData('HOUR');
    } else {
      const response = await axiosInstance.get<LineChartData>(url);
      const processedData = {
        ...response.data,
        priceHistory: response.data.priceHistory?.map(item => ({
            ...item,
            date: moment.utc(item.date).local().format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
        }))
      };
      return {
        priceHistory: processedData.priceHistory,
        assetName: processedData.assetName,
      };
    }
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
};

const getDividendChartData = async (assetId: number = 1, period: string = 'DAY'): Promise<LineChartData | undefined> => {
  const url = `dividend_history?asset_id=${assetId}&period=${period}`;
  try {
    if (isDevelopment) {
      return generateDividendLineChartData('HOUR');
    } else {
     const response = await axiosInstance.get<LineChartData>(url);
      const processedData = {
        ...response.data,
        dividendHistory: response.data.dividendHistory?.map(item => ({
            ...item,
            date: moment.utc(item.date).local().format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
        }))
      };
      return {
        dividendHistory: processedData.dividendHistory,
        assetName: response.data.assetName,
      };
    }
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
};

const getAssetInfo = (assetId: number = 1) => {
  const period = 'WEEK';
  const url = `asset?asset_id=${assetId}&period=${period}`;
  try {
    return axiosInstance.get(url).then((response) => {
      return response.data['data'];
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
};

const createUser = async (name: string, email: string, user_id: string) => {
  try {
    const response = await axiosInstance.post(`/auth`, {
      name: name,
      email: email,
      user_id: user_id
    });
    return response.data;
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw new Error("Failed create or update profile");
  }
}

const getPortFolioData = async (id: string | undefined) => {
  const url = id ? `portfolio/${id}` : 'portfolio';
  try {
    const response = await axiosInstance.get(url);
    const data = response.data;

    data.recentTransactions.data.forEach((transaction: any) => {
      const utcDate = transaction.data.date;
      const localDate = moment.utc(utcDate).local().format("ddd, DD MMM YYYY HH:mm:ss Z");
      transaction.data.date = localDate;
    });

    return data;
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
};

const getLeaderboardData = async () =>{
  const url = 'leaderboard'
  try {
    return axiosInstance.get(url).then((response) => {
      return response.data;
    })
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}


export {
  getAssets,
  getAssetInfo,
  getPm25ByHourAndDay,
  getScatterChartData,
  getMarketOverview,
  swap,
  getPriceChartData,
  getDividendChartData,
  userTokenQuantity,
  getAssetTableData,
  getPrice,
  createUser,
  getPortFolioData,
  getLeaderboardData,
  getPriceChangePrediction,
};
